import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import SEO from "../components/SEO";
import Logo from "../images/logo.svg";

const GlobalStyle = createGlobalStyle`
  html, body {
    padding: 0;
    margin: 0;
    direction: rtl;
  }
`;

const LogoWrapper = styled.main`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 80vmin;
        height: 80vmin;
    }
`;

const IndexPage: React.FC = () => {
    return (
        <>
            <SEO title="SailVibe - מועדון שיט" />
            <GlobalStyle />

            <LogoWrapper>
                <Logo />
            </LogoWrapper>
        </>
    );
};

export default IndexPage;
